import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'app/utils/tw';
import {
  RiLock2Line,
  RiInformationLine,
  RiCheckboxCircleLine,
} from '@remixicon/react';

const badgeVariants = cva(
  'whitespace-nowrap inline-flex items-center rounded-lg px-2 h-7 text-sm leading-none font-semibold transition-colors',
  {
    variants: {
      variant: {
        default: 'bg-homy-gray-lighter/25 text-homy-gray',
        info: 'bg-info-foreground text-info',
        warning: 'bg-warning-foreground text-[#854D0E]',
        success: 'bg-success-foreground text-success',
        destructive: 'bg-destructive-foreground text-destructive',
        notification:
          'bg-destructive text-white text-xs p-0 flex items-center justify-center',
      },
      size: {
        sm: 'w-2 h-2 text-opacity-0 select-none',
        md: 'w-4 h-4',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  icon?: boolean;
}

const iconSwitch = (variant: VariantProps<typeof badgeVariants>['variant']) => {
  switch (variant) {
    case 'default':
      return <RiLock2Line size={20} />;
    case 'info':
      return <RiInformationLine size={20} />;
    case 'warning':
      return <RiInformationLine size={20} />;
    case 'success':
      return <RiCheckboxCircleLine size={20} />;
    case 'destructive':
      return <RiInformationLine size={20} />;
    default:
      return <RiLock2Line size={20} />;
  }
};

function Badge({ className, variant, size, icon, ...props }: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({ variant, size }),
        'flex items-center gap-2',
        className,
      )}
      {...props}
    >
      {icon && iconSwitch(variant)}
      {props.children}
    </div>
  );
}

export { Badge, badgeVariants };
